<template>

  <div class="container">
    <div class="container_top" :style="{backgroundImage: 'url('+backPic+')'}">
      <div class="container_top_station" v-if="stationName">
        <div class="container_top_station_logo">
          <img :src=stationLogo>
        </div>
        <div class="container_top_station_name">
          {{stationName}}
        </div>
      </div>
    </div>
    <div class="container_main">
      <div class="container_main_block">
        <div class="container_main_block_left">
          <div class="container_main_block_left_first">
            <div
              class="container_main_block_bigbtn"
              @click="toNewActivityList"
            >
              <div class="container_main_block_bigbtn_icon"><img
                  src="../assets/images/flag.png"
                  alt=""
                ></div>
              <div class="container_main_block_bigbtn_title">最新活动</div>
            </div>
            <div
              class="container_main_block_btn container_main_block_btn--hot"
              @click="toHotActivityList"
            >
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/hot.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">热门活动</div>
            </div>
            <div
              class="container_main_block_btn container_main_block_btn--gonggao"
              @click="toAnnounce"
            >
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/gonggao.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">公告信息</div>
            </div>
          </div>
          <div class="container_main_block_left_second">
            <div class="container_main_block_btn container_main_block_btn--service" @click="toServiceMap">
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/service.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">服务地图</div>
            </div>
            <div
              class="container_main_block_btn container_main_block_btn--news"
              @click="toNews"
            >
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/news.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">新闻中心</div>
            </div>

            <div
              class="container_main_block_btn container_main_block_btn--station"
              @click="toStations"
            >
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/station.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">分站</div>
            </div>

            <div
              class="container_main_block_btn container_main_block_btn--shop"
              @click="toShop"
            >
              <div class="container_main_block_btn_icon"><img
                  src="../assets/images/shop.png"
                  alt=""
                /></div>
              <div class="container_main_block_btn_title">商城</div>
            </div>

          </div>

        </div>
        <div class="container_main_block_right">
          <div class="container_main_block_right_first">
            <div class="container_main_block_right_first_icon"><img
                src="../assets/images/tj.png"
                alt=""
              ></div>
            <div class="container_main_block_right_first_title">信息统计</div>
          </div>
          <div class="container_main_block_right_row">
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.memberNum}}</div>
              <div class="container_main_block_right_row_block_title">会员数</div>
            </div>
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.actNum}}</div>
              <div class="container_main_block_right_row_block_title">活动数</div>
            </div>
          </div>
          <div class="container_main_block_right_row">
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.stationNum}}</div>
              <div class="container_main_block_right_row_block_title">站点数</div>
            </div>
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.serverLength}}H</div>
              <div class="container_main_block_right_row_block_title">服务总时长</div>
            </div>
          </div>
          <div class="container_main_block_right_row">
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.teamNum}}</div>
              <div class="container_main_block_right_row_block_title">队伍数</div>
            </div>
            <div class="container_main_block_right_row_block">
              <div class="container_main_block_right_row_block_num">{{information == null ? 0 : information.orderMoney}}</div>
              <div class="container_main_block_right_row_block_title">兑换总金额</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container_main_buttom">
        <!--      友情链接部分-->
        <div class="container_main_buttom_link">
          <div class="container_main_buttom_link_item"  v-for="(item,index) in linkList"  :key="index">
            <a :href= item.linkUrl target="_blank">
            <img :src= item.linkImg>
            </a>
          </div>
        </div>
        <!--      底部信息-->
        <div class="container_main_buttom_info">
          <div class="container_main_buttom_link_item">
           {{basicInfo.bottominfo}}
          </div>
        </div>
      </div>
    </div>
    <div class="container_btns">
      <div
        class="container_btns_item container_btns_item--search"
        @click="toSearch"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/search.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          查询中心
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--member"
        @click="toMember"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/member.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          会员中心
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--member"
        @click="toPutOut"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/pub.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          发布活动
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--signed"
        @click="toSignedActivityList"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/signed.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          已报名
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--comment"
        @click="toCommentActivityList"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/comment.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          活动评价
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--apply"
        @click="toClaimActivityList('longActivity')"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/apply.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          认领活动
        </div>
      </div>
      <div
        class="container_btns_item container_btns_item--apply"
        @click="toClaimActivityList('claimed')"
      >
        <div class="container_btns_item_icon">
          <img
            src="../assets/images/applyed.png"
            alt=""
          >
        </div>
        <div class="container_btns_item_title">
          已认领
        </div>
      </div>

    </div>
  </div>
  <el-dialog
    v-model="dialogVisible"
    title="选择站点"
  >
    <el-table
      :data="stationList"
      @current-change="handleCurrentChange"
      highlight-current-row
    >
      <el-table-column
        type="index"
        width="50"
      />
      <el-table-column
        property="name"
        label="站点名称"
        width="200"
      />
      <el-table-column
        property="address"
        label="地址"
      />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancleStation">取消</el-button>
        <el-button
          type="primary"
          @click="confirmStatation"
        >确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup name="Home">
import { useRouter } from "vue-router";
import { getStationList,informationcount,getButtomInfoAndLink,getBanner } from "@/api/home";
import { ref } from "@vue/reactivity";
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'
import {getUserInfo} from "@/api/user";
const store = useStore();
const dialogVisible = ref(true)
const currentRow = ref()
const router = useRouter();
const backPic = ref();
const information = ref();
const query=ref({});
const stationId = ref();
//当前站点
const stationName = ref();
//当前logo
const stationLogo = ref();
//分站list
const stationList = ref([]);
//友情链接list
const linkList = ref([]);
//底部信息
const basicInfo = ref({});
//获得站点列表
const getStation = async () => {
  let host = window.location.host
  console.log("host====",host)
  let response = await getStationList();
  if (response.rows.length > 0) {
    stationList.value = response.rows
    console.log("stationList.value===",stationList.value)
    for(let item of stationList.value){
      if(item.bindDomain===host){
          store.commit("setStationIds", item.id);
          stationId.value = item.id;
          localStorage.setItem("stationName",item.name);
          stationName.value = item.name;
          localStorage.setItem("stationLogo",item.imgUrl);
          stationLogo.value = item.imgUrl;
          getInformation();
      }
    }
  } else {
    stationList.value = [];
  }
}



//获取首页信息
const getInformation = async () => {
  if(store.state.stationId != null && store.state.stationId != 'undefined'){
    query.value.id = store.state.stationId;
  }
  let response = await informationcount(query.value);
  if (response.code == 200) {
    information.value = response.data
  }
}


const initData = async () => {
  if (store.state.stationId == null) {
    getStation();
    //let result = await getMainStationInfo();
    //store.commit("setStationIds",result.data.id);
  } else {
    dialogVisible.value = false
  }
}
initData();
getInformation();
const handleCurrentChange = (val) => {
  currentRow.value = val
  console.log("row==", currentRow.value.id)
}

const cancleStation = () => {
  dialogVisible.value = false
}

const confirmStatation = () => {
  if (currentRow.value == null) {
    ElMessage.error('请选择站点!')
    return false;
  }
  dialogVisible.value = false
  store.commit("setStationIds", currentRow.value.id);
  stationId.value = currentRow.value.id;
  localStorage.setItem("stationName",currentRow.value.name);
  stationName.value = currentRow.value.name;
  localStorage.setItem("stationLogo",currentRow.value.imgUrl);
  stationLogo.value = currentRow.value.imgUrl;
  getInformation();
}

const toHotActivityList = () => {
  router.push({
    path: "/hotActivityList",

  });
}
const toServiceMap = ()=>{
   router.push({
    path: "/actMap",

  });
}

const toClaimActivityList = async (active) => {
  let response=await getUserInfo();
  if(active == 'longActivity'){
    if(response.data.type == '1' || response.data.type == '2'){
      ElMessage.error("您不能认领活动");
      return;
    }
  }else {
    if(response.data.type == '1' || response.data.type == '2'){
      ElMessage.error("您没有权限查看此功能");
      return;
    }
  }

  router.push({
    path: "/claimActivityList",
    query: {
      active
    },
  });
}
const toSignedActivityList = () => {
  router.push({
    path: "/signedActivityList",

  });
}
const toCommentActivityList = () => {
  router.push({
    path: "/commentActivityList",

  });
}
const toNewActivityList = () => {
  router.push({
    path: "/newActivityList",

  });
}
const toPutOut = async () => {
  let response=await getUserInfo();
  if(response.data.type == '1' || response.data.type == '2'){
    ElMessage.error("您不能发布活动");
    return;
  }
  router.push({
    path: "/putOut",

  });
}
const toAnnounce = () => {
  router.push({
    path: "/announceList",

  });
}
const toNews = () => {
  router.push({
    path: "/newsList",

  });
}
const toStations = () => {
  router.push({
    path: "/stations",

  });
}

const toSearch = async () => {
  router.push({
    path: "/searchCenter"
  })
}
const toShop = () => {
  router.push({
    path: "/shopIndex"
  })
}
const toMember = () => {
  router.push({
    path: "/memberCenter"
  })
}

const getBottomInfo = async () =>{
  let response = await getButtomInfoAndLink();
  if(response.code === 200){
    linkList.value = response.data.link;
    basicInfo.value = response.data.basicInfo;
  }
}

//从localStorage拿数据
const  getStationName = () =>{
  if(localStorage.getItem("stationName") != null){
    stationName.value = localStorage.getItem("stationName");
    stationLogo.value = localStorage.getItem("stationLogo");
  }
}

const getBannerInfo = async () =>{
  let response = await getBanner();
  if(response.code === 200){
    backPic.value = response.data.link;
  }
}
getBottomInfo();
getStationName();
getBannerInfo();


</script>
<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  &_btns {
    position: fixed;
    right: 0;
    bottom: 80px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    &_item {
      margin-top: 30px;
      padding: 20px 30px 20px 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 10px 0px 0px 10px;
      &--search {
        background: #7ab8fb;
      }
      &--member {
        background: #ff9959;
      }
      &--signed {
        background: #00e676;
      }
      &--comment {
        background: #59dbff;
      }
      &--apply {
        background: #8e60f6;
      }
      &_icon {
        margin-right: 10px;
      }
      &_icon img {
        height: 30px;
      }
      &_title {
        color: #ffffff;
        font-size: 20px;
      }
    }
  }
  &_top {
    background: url(../assets/images/topbg.png) no-repeat;
    background-size: 100% 100%;
    height: 420px;
    z-index: 1;

    &_station{
      margin-top: 90px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      &_logo img{
        width: 68px;
        height: 68px;
      }
      &_name{
        margin-top: 5px;
        margin-left: 30px;
        color: rgba(255, 255, 255, 100);
        font-size: 48px;
        font-family: HarmonyOS_Sans_SC-regular;
      }
    }

  }
  &_main {
    background: url(../assets/images/mainbg.png) no-repeat;
    background-size: 100% 100%;
    height: 100%;
    margin-top: -100px;
    padding-bottom: 80px;
    &_block {
      margin-top: 180px;
      margin-left: 80px;
      display: flex;
      flex-direction: row;
      &_left {
        display: flex;
        flex-direction: column;
        &_first {
          display: flex;
          flex-direction: row;
        }
        &_second {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
        }
      }
      &_right {
        display: flex;
        flex-direction: column;
        background: url(../assets/images/stasticbg.png) no-repeat;
        height: 500px;
        width: 500px;
        border-radius: 5px;
        &_first {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &_icon {
            margin-right: 20px;
          }
          &_icon img {
            height: 40px;
          }
          &_title {
            font-size: 28px;
            color: #ffffff;
          }
        }
        &_row {
          display: flex;
          flex-direction: row;

          &_block {
            margin-top: 40px;
            margin-left: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;
            &_num {
              font-size: 36px;
              color: #ffffff;
            }
            &_title {
              margin-top: 15px;
              font-size: 14px;
              color: #ffffff;
            }
          }
        }
      }
      &_bigbtn {
        margin-right: 20px;
        width: 500px;
        height: 240px;
        border-radius: 5px;
        background-color: #ff9959;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &_icon {
        }
        &_icon img {
          height: 80px;
        }
        &_title {
          margin-top: 30px;
          color: #ffffff;
          font-size: 28px;
        }
      }
      &_btn {
        margin-right: 20px;
        width: 240px;
        height: 240px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &--hot {
          background: #ff7272;
        }
        &--gonggao {
          background: #c465e2;
        }
        &--service {
          background: #59dbff;
        }
        &--news {
          background: #7ab8fb;
        }
        &--station {
          background: #ff9959;
        }
        &--shop {
          background: #fedd59;
        }
        &_icon {
        }
        &_icon img {
          height: 80px;
        }
        &_title {
          margin-top: 30px;
          color: #ffffff;
          font-size: 28px;
        }
      }
    }
    &_buttom{
      margin-top: 30px;
      &_link{
        display: flex;
        justify-content: center;
        &_item  {
          img{
            display: block;
            height: 60px;
            margin: 10px;
          }
        }
      }
      &_info{
        margin-top: 10px;
      }

    }
  }
}
</style>
